{
    "default": {
        "okta": {
            "cid": "0oar4bcx6gkJD01eV1t7",
            "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefix": "https://order-details.cs-tools.dev.ring.com"
    },
    "order-details.cs-tools.dev.ring.com": {
        "okta": {
            "cid": "0oar4bcx6gkJD01eV1t7",
            "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefix": "https://order-details.cs-tools.dev.ring.com"
    },
    "order-details.cs-tools.qa.ring.com": {
        "okta": {
            "cid": "0oar4bcx6gkJD01eV1t7",
            "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefix": "https://order-details.cs-tools.qa.ring.com"
    },
    "order-details.cs-tools.ring.com": {
        "okta": {
            "cid": "0oargm6a2e2nX36SR1t7",
            "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefix": "https://order-details.cs-tools.ring.com"
    }
}