//PAGE
import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import PageContent from '../components/layouts/PageContent';
//API
import axios from 'axios';
import settings from '../settings.json';
//COMPONENTS
import { Button, Card, Divider, message, Popover, Spin } from 'antd';
import { CloseCircleOutlined, EyeInvisibleOutlined, ExpandAltOutlined, LoadingOutlined, SendOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
//SEARCH PARAM
import { useSearchParams } from 'react-router-dom';

import { useContinuousAuth } from './App';
//get api prefix
const settingEnv = (settings as any)[window.location.host] || settings.default;
const apiPrefix = settingEnv.api_prefix;

export function OrderDetailsPage() {
    return (
        <PageContent
            header='Order Details'
            authenticated={true}
        >
            <AuthedOrderDetailsPage />
        </PageContent>
    );
}

//format price paid as dollar amount
export const pricePaid = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

//styled components
const PageWrapper = styled.div`
`;
const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 300px;
    border-radius: 5px;
    background-color: #fff;
`;
const OrderBody = styled.div`
    margin: -20px 0px 40px 0px;

    font-family: 'equiplight';
    font-weight: 500;
`;
// Order Card
const OrderDetailsContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    font-family: 'equiplight';
    font-weight: 500;

    @media (max-width: 900px) {
        flex-direction: column;
    }
`;
const OrderHeader = styled.div`
    color: #10628A;
    font-family: 'equipmedium';
    font-size: 18px;
    margin: 0px 50px 0px 0px;
`;
const OrderSubHeader = styled.span`
    font-family: 'equiplight';
    font-weight: 500;
`;
const OrderSubDetails = styled.div`
    display: flex;
    margin-left: auto;

    @media (max-width: 750px) {
        width: 100%;
        margin-left: 0px;
    }
`;
const OrderLabel = styled.p`
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    margin: 5px 10px 0px 0px;
    
`;
const OrderValue = styled.p`
    font-size: 14px;
    margin: 5px auto 5px 0px;
`;

//Order Item Card
const OrderItemLabel = styled.p`
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    margin: 5px 10px 0px 0px;
    
`;
const OrderItemValue = styled.p`
    font-size: 14px;
    margin: 5px 25px 5px 0px;
`;

const ShippedIndicator = styled.div`
    border-right: 1px solid #f0f0f0;
    color: rgb(24, 144, 255);
    margin-right: 10px;
    padding-right: 10px;
`;
const NotShippedIndicator = styled.div`
    border-right: 1px solid #f0f0f0;
    color: #b5b5b5;
    margin-right: 10px;
    padding-right: 10px;
`;
const ItemHeader = styled.div`
    align-items: center;
    color: #424242;
    cursor: pointer;
    display: flex;
    font-family: 'equipmedium';
    font-size: 14px;
    
    &:hover ${ShippedIndicator} {
        color: #10628A;
    }
    &:hover ${NotShippedIndicator} {
        color: #424242;
    }
`;
const ShippedBundleHeader = styled.div`
    flex-basis: 90%;

`;
const ItemHeaderText = styled.div`
    flex: auto 1 1;
    display: flex;
    flex-wrap: wrap;

    & > p {
        padding: 0px 10px 0px 0px;
        margin: 0px 10px 0px 0px;
        border-right: 1px solid #f0f0f0;

        &:last-child {
            border-right: 0px;
        }

        @media (max-width: 750px) {
            font-family: 'equiplight';
            border-right: 0px;
        }
    }

    @media (max-width: 750px) {
        flex-direction: column;
    }
`;
const ItemHeaderRight = styled.div`
    align-items: center;
    display: flex;

    & > p {
        font-size: 12px;
        padding-right: 10px;
        margin: 0px;    
    }
`;

//Item Details Card
const ItemDetails = styled.div<{
    expanded?: boolean;
}>`
    margin: 0px;
    overflow: hidden;
    max-height: ${props => props.expanded ? 'none' : '0px'};
`;
const ItemDetailsSection = styled.div`
    border: 1px solid #f0f0f0;
    padding: 10px;
    width: 375px;
    margin-bottom: 15px;

    & > p {
        margin: 0px 10px 0px 0px;
    }
`;

//Cancel Order
const CancelOrderButton = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;
const CancelOrderWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    @media (max-width: 900px) {
        flex-direction: column;
    }
`;
const CancelPopupBody = styled.div`
`;
const CancelPopupHeader = styled.div`
    color: #1890ff;
`;
const CancelPopupText = styled.div`
`;

interface OrderHeader {
    shopify_order_number: string;
    order_date: string;
    order_status: string;
}

interface OrderDetails {
    orderHeader: OrderHeader;
    orderItemsArray?: OrderItem[];
    fulfillmentRequestIdList?: string[];
    salesStatus: boolean;
    shipmentStatus: boolean;
}

interface OrderItem {
    bundle: string;
    id: string;
    description: string;
    paid: number;
    quantity: number;
    shippableItems?: ShippableItem[];
}

interface ShippableItem {
    bundle: string;
    id: string;
    fulfillmentDetails?: FulfillmentDetails;
    item: string;
    itemOrdered: string;
    quantity: number;
    shipmentDetails?: ShipmentDetails[];
}

interface FulfillmentDetails {
    arrivalDate: string;
    bundle: string;
    id: string;
    item: string;
    itemOrdered: string;
    quantityOrdered: number;
    quantityShipped: number;
    shipDate: string;
}

interface ShipmentDetails {
    actual_ship_date: string;
    carrier: string;
    eta: string;
    id: string;
    item: string;
    itemOrdered: string;
    quantity: number;
    shipment_id: string;
    tracking: string;
}

interface OrderItemProps {
    displayId: string;
    setDisplayId: React.Dispatch<React.SetStateAction<string>>;
    item: OrderItem;
}

interface ShippableItemDetailsProps {
    shippableItem: ShippableItem;
    expanded: boolean;
}

interface ShippableItemProps {
    shippableItem: ShippableItem;
    showDetails: boolean;
    toggleShowDetails: () => void;
}

interface CancelOrderProps {
    shipmentStatus: boolean;
    fulfillmentRequestIdList: string[];
    orderId: string;
}

export const ShippableItemDetails: React.FC<ShippableItemDetailsProps> = (props: ShippableItemDetailsProps) => {
    const {
        shippableItem,
        expanded
    } = props;

    return (
        <ItemDetails
            expanded={expanded}
        >
            <Divider
                orientation='left'
                style={{
                    color: '#10628A',
                }}
            > Fulfillment Details
            </Divider>
            <ItemDetailsSection>
                <p>Estimated Ship Date: {shippableItem?.fulfillmentDetails?.shipDate || 'N/A'}</p>
                <p>Estimated Arrival Date: {shippableItem?.fulfillmentDetails?.arrivalDate || 'N/A'}</p>
                <p>Quantity Ordered: {shippableItem?.fulfillmentDetails?.quantityOrdered || 'N/A'}</p>
                <p>Quantity Shipped: {shippableItem?.fulfillmentDetails?.quantityShipped || 'N/A'}</p>
            </ItemDetailsSection>
            <Divider
                orientation='left'
                style={{ color: '#10628A' }}
            > Shipment Details
            </Divider>
            {
                shippableItem?.shipmentDetails?.map(shipment => (
                    <ItemDetailsSection key={shipment.id}>
                        <p>Carrier: {shipment.carrier || 'N/A'}</p>
                        <p>Ship Date: {shipment.actual_ship_date || 'N/A'}</p>
                        <p>Tracking Number: {shipment.tracking || 'N/A'}</p>
                        <p>Estimated Arrival Date: {shipment.eta || 'N/A'}</p>
                        <p>Quantity: {shipment.quantity || 'N/A'}</p>
                    </ItemDetailsSection>
                ))
            }
        </ItemDetails>
    );
};

export const ShippableItem: React.FC<ShippableItemProps> = (props: ShippableItemProps) => {
    const {
        shippableItem,
        showDetails,
        toggleShowDetails
    } = props;

    const showShipmentSummaryInHeader = shippableItem?.bundle === 'F' && shippableItem.shipmentDetails?.length === 1;
    const showShipmentSummaryInHeaderBundle = shippableItem?.bundle === 'T' && shippableItem.shipmentDetails?.length === 1;
    let headerTextArray = [];
    let shipmentStatus;
    if (showShipmentSummaryInHeader) {
        const shipment = shippableItem.shipmentDetails?.[0];
        headerTextArray = [
            `Carrier: ${shipment?.carrier || 'N/A'}`,
            `Ship Date: ${shipment?.actual_ship_date || 'N/A'}`,
            `Tracking #: ${shipment?.tracking || 'N/A'}`,
            `Est. Arrival: ${shipment?.eta || 'N/A'}`,
            `Quantity: ${shipment?.quantity || 'N/A'}`,
        ];
        shipmentStatus = (
            <ShippedIndicator>
                <SendOutlined style={{ marginRight: '5px' }} /> Shipped
            </ShippedIndicator>
        );
    } else if (showShipmentSummaryInHeaderBundle) {
        const shipment = shippableItem.shipmentDetails?.[0];
        headerTextArray = [
            `Carrier: ${shipment?.carrier || 'N/A'}`,
            `Ship Date: ${shipment?.actual_ship_date || 'N/A'}`,
            `Tracking #: ${shipment?.tracking || 'N/A'}`,
            `Est. Arrival: ${shipment?.eta || 'N/A'}`,
            `Quantity: ${shipment?.quantity || 'N/A'}`,
        ];
        shipmentStatus = (
            <>
                <ShippedIndicator>
                    <SendOutlined style={{ marginRight: '5px' }} /> Shipped
                </ShippedIndicator>
                <ShippedBundleHeader>{shippableItem.item || 'N/A'}</ShippedBundleHeader>
            </>
        );
    } else if (shippableItem?.shipmentDetails) {
        headerTextArray = [
            `${shippableItem.item || 'N/A'}`,
        ];
        shipmentStatus = (
            <ShippedIndicator>
                <ExpandAltOutlined style={{ marginRight: '5px' }} /> Multiple Shipments
            </ShippedIndicator>
        );
    } else {
        headerTextArray = [
            `${shippableItem?.item || 'N/A'}`,
        ];
        shipmentStatus = (
            <NotShippedIndicator>
                <CloseCircleOutlined style={{ marginRight: '5px' }} /> Details
            </NotShippedIndicator>
        );
    }

    let fulfillmentStatus;
    if (showShipmentSummaryInHeader) {
        fulfillmentStatus = '';
    } else if (showShipmentSummaryInHeaderBundle) {
        fulfillmentStatus = '';
    } else if (shippableItem?.fulfillmentDetails) {
        fulfillmentStatus = `${shippableItem.fulfillmentDetails.quantityShipped} of ${shippableItem.fulfillmentDetails.quantityOrdered} Items Shipped`;
    } else {
        fulfillmentStatus = '';
    }

    return (
        <div>
            <ItemHeader onClick={() => toggleShowDetails()} data-testid='ShippableItemHeader'>
                <ItemHeaderText>
                    {!showDetails ? shipmentStatus :
                        <ShippedIndicator>
                            <EyeInvisibleOutlined style={{ marginRight: '5px' }} /> Hide Details
                        </ShippedIndicator>
                    }
                    {headerTextArray.map((headerText, index) => (
                        <p key={index}>{headerText}</p>
                    ))}
                </ItemHeaderText>
                <ItemHeaderRight>
                    {fulfillmentStatus}
                </ItemHeaderRight>
            </ItemHeader>
            <ShippableItemDetails
                expanded={showDetails}
                shippableItem={shippableItem}
            />
        </div>
    );
}

export const OrderItem: React.FC<OrderItemProps> = (props: OrderItemProps) => {
    const {
        displayId,
        setDisplayId,
        item
    } = props;

    return (
        <Card
            title={
                <OrderDetailsContent>
                    <OrderHeader>{item.description || 'N/A'}</OrderHeader>
                    <OrderSubDetails>
                        <OrderItemLabel>Paid:</OrderItemLabel>
                        <OrderItemValue>{pricePaid.format(item.paid) || 'N/A'}</OrderItemValue>
                        <OrderItemLabel>Quantity:</OrderItemLabel>
                        <OrderItemValue>{item.quantity || 'N/A'}</OrderItemValue>
                    </OrderSubDetails>
                </OrderDetailsContent>
            }
            headStyle={{
                backgroundColor: '#F6F8FA'
            }}
            style={{
                width: '100%',
                marginBottom: '25px'
            }}
        >
            {item.shippableItems?.map((shippableItem, index) => (
                <Fragment key={shippableItem.id}>
                    {index !== 0 && (
                        <Divider
                            orientation='left'
                            style={{ margin: '10px 0px' }}
                        />
                    )}
                    <ShippableItem
                        shippableItem={shippableItem}
                        showDetails={displayId === shippableItem.id}
                        toggleShowDetails={() => setDisplayId((oldId: string) => oldId !== shippableItem.id ? shippableItem.id : '')}
                    />
                </Fragment>
            ))}
        </Card>
    );
}

export const CancelOrder: React.FC<CancelOrderProps> = (props: CancelOrderProps) => {
    const {
        shipmentStatus,
        fulfillmentRequestIdList,
        orderId
    } = props;

    const [cancelDisabled, setCancelDisabled] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);

    const { getHeaders } = useContinuousAuth();

    // Create a cancel order request for each fulfillment request ID
    const cancelOrderRequests = async () => {
        setCancelLoading(true);
        setCancelDisabled(true);
        try {
            const responses = await Promise.allSettled(
                fulfillmentRequestIdList.map(fetchOrderCancellation)
            );

            // Check for failed cancel requests (partial failure or full failure results in ALT escalation)
            const hasRejectedRequests = responses.some(
                (response) => response.status === 'rejected'
            );

            // Check for failed cancel requests (partial failure or full failure results in ALT escalation)
            if (hasRejectedRequests) {
                Swal.fire({
                    title: 'Warning',
                    text: `There was an error with your Cancel request. Fulfillment Request ID(s): ${fulfillmentRequestIdList.join()}.
                    Please escalate the cancellation requests to the ALT team and share the "FR" numbers associated.`,
                    icon: 'error',
                });
            }
            // If no requests failed, return success message
            else {
                Swal.fire({
                    title: 'Success!',
                    text: `Your Cancel Order Request has been submitted | ID: ${fulfillmentRequestIdList.join()}`,
                    icon: 'success',
                });
            }
        } finally {
            setCancelLoading(false);
        }
    };

    const fetchOrderCancellation = async (fulfillmentRequestId: string) => {
        const headers = await getHeaders();
        const requestPayload = { orderId: orderId, fulfillmentRequestId: fulfillmentRequestId };
        await axios.post(apiPrefix + '/api/order-cancellation', requestPayload, { headers });
    };

    // Cancel Button On-Hover (Disabled States)
    const popoverContent = (
        <CancelPopupBody>
            <CancelPopupText>
                Please escalate the cancellation requests for partially shipped orders to the ALT team and share the "FR" number. <br />
                Fulfillment Request #: {fulfillmentRequestIdList.join()} <br />
                Orders that are completely shipped cannot be cancelled, please ask the neighbor to request a return to process the refund. <br />
            </CancelPopupText>
        </CancelPopupBody>
    );

    const popoverHeader = (
        <CancelPopupHeader>
            <CloseCircleOutlined style={{ marginRight: '5px' }} />
            Order Cancellation Cannot be Processed
        </CancelPopupHeader>
    );

    return (
        <CancelOrderWrapper>
            <CancelOrderButton>
                {shipmentStatus ?
                    <Popover content={popoverContent} title={popoverHeader} trigger='hover' placement='topLeft'>
                        <Button
                            disabled={shipmentStatus}
                            type="primary"
                            onClick={cancelOrderRequests}
                            icon={
                                cancelLoading ?
                                    <LoadingOutlined />
                                    : <SendOutlined />
                            }
                        >
                            Cancel Order
                        </Button>
                    </Popover> :
                    <Button
                        disabled={cancelDisabled}
                        type="primary"
                        onClick={cancelOrderRequests}
                        icon={
                            cancelLoading ?
                                <LoadingOutlined />
                                : <SendOutlined />
                        }
                    >
                        Cancel Order
                    </Button>
                }
            </CancelOrderButton>
        </CancelOrderWrapper>
    );

}

// Order Details Page Content
export function AuthedOrderDetailsPage() {

    //order id from url param
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get('orderId') || '';
    // order details
    const [loading, setLoading] = useState(true);
    const [orderDetails, setOrderDetails] = useState<OrderDetails | undefined>(undefined);
    // additional details flag
    const [displayId, setDisplayId] = useState('');
    // cancel request
    const [closedStatus, setClosedStatus] = useState(false);
    const [salesStatus, setSalesStatus] = useState(false);
    const [shipmentStatus, setShipmentStatus] = useState(false);
    const [fulfillmentRequestIdList, setFulfillmentRequestIdList] = useState<string[]>([]);

    const { getHeaders } = useContinuousAuth();

    //get order details
    const getOrderDetails = async (orderId: string) => {
        const params = { orderId };
        const headers = await getHeaders();
        const { data } = await axios.get(apiPrefix + '/api/order-details', { headers, params });
        return data as OrderDetails;
    };

    useEffect(() => {
        if (orderId) {
            setLoading(true);
            getOrderDetails(orderId)
                .then((details) => {
                    setOrderDetails(details);
                    setClosedStatus(details.orderHeader.order_status === 'Order closed');
                    setFulfillmentRequestIdList(details.fulfillmentRequestIdList || []);
                    setShipmentStatus(!!details.shipmentStatus);
                    setSalesStatus(!!details.salesStatus);
                })
                .catch(e => {
                    message.error('There was a problem with your Order Details request');
                    console.log('=======error', e.message);
                })
                .finally(() => setLoading(false));
        }
    }, [orderId]);

    return (
        <PageWrapper>
            {loading ?
                orderId ?
                    (
                        <LoadingWrapper>
                            <Spin
                                size='large'
                                data-testid='loading-spinner'
                            />
                        </LoadingWrapper>
                    )
                    : (
                        <Card title='Please specify an order ID to begin' style={{ width: '100%', }} />
                    )
                : (
                    <Card
                        title={
                            <OrderDetailsContent>
                                <OrderHeader>Order Details</OrderHeader>
                                <OrderLabel>Order Number:</OrderLabel>
                                <OrderValue>{orderDetails?.orderHeader.shopify_order_number}</OrderValue>
                                <OrderLabel>Order Date:</OrderLabel>
                                <OrderValue>{orderDetails?.orderHeader.order_date}</OrderValue>
                                <OrderLabel>Order Status:</OrderLabel>
                                <OrderValue>{orderDetails?.orderHeader.order_status}</OrderValue>
                            </OrderDetailsContent>
                        }
                        style={{
                            width: '100%',
                        }}
                    >
                        {orderDetails &&
                            <OrderBody>
                                <Divider
                                    orientation='right'
                                    style={{
                                        color: '#10628A',
                                    }}
                                >
                                    <ShoppingCartOutlined
                                        style={{ marginRight: '5px' }}
                                    />
                                    <OrderSubHeader>
                                        Order Items ({orderDetails?.orderItemsArray?.length})
                                    </OrderSubHeader>
                                </Divider>
                                {orderDetails?.orderItemsArray?.map((item: OrderItem) => (
                                    <OrderItem
                                        key={item.id}
                                        item={item}
                                        displayId={displayId}
                                        setDisplayId={setDisplayId}
                                    />
                                ))}
                                {/* 
                                Testing ONLY stage check & allowedUsers check.
                                (only display in Beta/Gamma OR allowedSubs can test in prod on completed orders per Russell)
                                Remove both checks prior to Production Release 
                                */}
                                {(salesStatus && !closedStatus) &&
                                    <CancelOrder
                                        shipmentStatus={shipmentStatus}
                                        fulfillmentRequestIdList={fulfillmentRequestIdList}
                                        orderId={orderId}
                                    />
                                }
                            </OrderBody>
                        }
                    </Card>
                )}
        </PageWrapper>
    );
}