import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import ringIconUrl from 'url:../../images/ring_logo_white.png';

const HeaderWrapper = styled.div<{
    color: string;
}>`
    width: 100%;
    background-color: ${props => props.color};

    display: flex;
    flex-direction: row;
    align-items: center;
`;

const HeaderLeftWrapper = styled.div`
    flex: 1 1 1px;
    padding: 5px;

    display: flex;
    flex-direction: row;
`;

const HeaderRightWrapper = styled.div`
    flex: 1 1 1px;
    padding: 5px;

    display: flex;
    flex-direction: row-reverse;
`;

const LogoImg = styled.img`
    align-self: center;
    margin: 0px;
    padding: 5px 15px;

    height: 50px;
    width: auto;

    cursor: pointer;
`;

const HeaderContainer = styled.div`
    align-self: center;
    display: flex;
    flex-direction: column;

    color: white;
    padding: 5px 5px 5px 10px;
    border-left: white 1px solid;

    & > h1 {
        color: white;
        font: 22px equipextralight;
        margin: 0px;
    }

    & > p {
        font: 16px equiplight;
        margin: 0px;
    }
`;

type Props = PropsWithChildren<{
    headerColor?: string;
    header: string;
    subheader?: string;
}>;

function PageHeader(props: Props) {
    const {
        children,
        headerColor = '#404041',
        header,
        subheader,
    } = props;

    return (
        <HeaderWrapper color={headerColor}>
            <HeaderLeftWrapper>
                <LogoImg
                    src={ringIconUrl}
                    title='iconheader'
                />
                <HeaderContainer>
                    <h1>{header}</h1>
                    {subheader && <p>{subheader}</p>}
                </HeaderContainer>
            </HeaderLeftWrapper>
            <HeaderRightWrapper>
                {children}
            </HeaderRightWrapper>
        </HeaderWrapper>
    );
}

export default PageHeader;